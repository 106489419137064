import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as TradeTariffsIcon } from '../assets/trade-tariffs.svg';

const topic = {
  "title": "Trade tariffs",
  "results": {
    "deal": {
      "status": "red",
      "comment": "UK would pay tariffs"
    },
    "disorderly": {
      "status": "red",
      "comment": "UK would pay tariffs"
    },
    "remain": {
      "status": "green",
      "comment": "Tariff free trade with EU"
    },
    "reform": {
      "status": "green",
      "comment": "Tariff free trade with EU"
    }
  }
};

const TradeTariffsTopic = () => (
  <Topic topic={topic} icon={TradeTariffsIcon}>
    <p>
      If we leave the EU then tariffs will be charged on goods entering the UK, leading
      to price increase for British people. Tariffs would also be charged on goods
      leaving the UK, which would damage UK businesses that export goods to the EU.
      An example of WTO tariffs that could be implemented in a no deal Brexit are:
    </p>
    <ul>
      <li>12% for fish - disrupting our fishing industry</li>
      <li>35% for dairy products - damaging our farming industry</li>
      <li>10% for fruit and vegetables</li>
    </ul>
    <p>
      All of these would see cause higher prices at the checkout
      <OutboundLink href="https://www.bbc.co.uk/news/uk-45112872">(source)</OutboundLink>.
    </p>
  </Topic>
);

export default TradeTariffsTopic;
