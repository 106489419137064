import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as GdpIcon } from '../assets/gdp.svg';

const topic = {
  "title": "GDP",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "3-4% drop in GDP"
    },
    "disorderly": {
      "status": "red",
      "comment": "5-9% drop in GDP"
    },
    "remain": {
      "status": "green",
      "comment": "Rise in GDP"
    },
    "reform": {
      "status": "green",
      "comment": "Rise in GDP"
    }
  }
};

const GdpTopic = () => (
  <Topic topic={topic} icon={GdpIcon}>
    <p>
      The National Institute of Economic and Social Research's (NIESR) study
      modelled different Brexit scenarios against a baseline of staying in the
      EU. It found that the withdrawal agreement would lead to a huge reduction
      in trade and investment. By 2030, at the end of the first decade outside
      the EU, the research predicts that GDP per head would fall by 3%,
      amounting to an average cost per person of £1,090 at today's prices. It
      also estimates that total trade between the UK and the EU would fall by
      46%. The report also modelled alternative Brexit outcomes against staying
      in the EU. A scenario of a "no deal" departure from the EU would
      reduce GDP by 5.5%, or £140bn, it said
      <OutboundLink href="https://www.bbc.co.uk/news/business-46338585">(source)</OutboundLink>.
    </p>
    <p>
      The Financial Times predicts even larger drops in GBP. May's deal would
      lead to a 4% drop in GDP, or 9% in the event of no deal.
    </p>
    <p>
      <b>In real terms:</b><br/>
      These drops are much larger than the drop in the 2008 recession.<br/>
      May's deal would leave income per capita 1.7% worse off. The equivalent figure for the Johnson deal is 2.5% lower while that for a no-deal Brexit is 3.3%
      <OutboundLink href="https://www.ft.com/content/22c38654-f00e-11e9-ad1e-4367d8281195">(source)</OutboundLink>
    </p>
  </Topic>
);

export default GdpTopic;
