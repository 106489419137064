import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EconomyIcon } from '../assets/economy.svg';

const topic = {
  "title": "Strength of £",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "Uncertainty leads to a weak, unstable pound"
    },
    "disorderly": {
      "status": "red",
      "comment": "Pound projected to drop further with hard Brexit"
    },
    "remain": {
      "status": "green",
      "comment": "EU membership brought a strong pound"
    },
    "reform": {
      "status": "green",
      "comment": "EU membership brought a strong pound"
    }
  }
};

const StrengthOfPoundTopic = () => (
  <Topic topic={topic} icon={EconomyIcon}>
    <p>
      The value of the pound plummeted after the result of the referendum. It is
      expected to drop further when Britain leaves the EU with a greater drop in
      the event of a "no deal".
    </p>
  </Topic>
);

export default StrengthOfPoundTopic;
