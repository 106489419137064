import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as Economy2Icon } from '../assets/economy2.svg';

const topic = {
  "title": "Economy",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "UK government projects 2-8% reduction in growth"
    },
    "disorderly": {
      "status": "red",
      "comment": "UK government projects 8% reduction in growth"
    },
    "remain": {
      "status": "green",
      "comment": "Steady growth predicted if we remain in the EU"
    },
    "reform": {
      "status": "green",
      "comment": "Steady growth predicted if we remain in the EU"
    }
  }
};

const EconomyTopic = () => (
  <Topic topic={topic} icon={Economy2Icon}>
    <p>
      In September the IMF’s managing director, Christine Lagarde, visiting the UK
      in relation to the Brexit talks, said “Whatever the deal is, it will not be
      as good as at the moment”
      <OutboundLink href="https://www.independent.co.uk/news/business/news/brexit-no-deal-news-imf-forecast-gdp-world-trade-organisation-economy-a8633681.html" target="_blank">(source)</OutboundLink>.
    </p>
    <p>
      The UK will be worse off after leaving the European Union in every likely scenario.
    </p>
    <p>
      The analysis examined the three most plausible scenarios for the UK’s
      departure — a comprehensive free-trade deal with the EU, a no-deal Brexit,
      and a soft Brexit with continued membership in the European Economic Area
      and the single market — and found that under each, Britain would be worse
      off in 15 years than if it remained in the bloc
      <OutboundLink href="https://www.politico.eu/article/report-uk-worse-off-in-any-likely-post-brexit-scenario-says-government-analysis/" target="_blank">(source)</OutboundLink>.
    </p>
  </Topic>
);

export default EconomyTopic;
