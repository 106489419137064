import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as DivorceBillIcon } from '../assets/divorce-bill.svg';

const topic = {
  "title": "Divorce Bill",
  "results": {
    "deal": {
      "status": "red",
      "comment": "£39 billion estimated divorce bill"
    },
    "disorderly": {
      "status": "amber",
      "comment": "£30 billion estimated divorce bill"
    },
    "remain": {
      "status": "green",
      "comment": "No divorce bill"
    },
    "reform": {
      "status": "green",
      "comment": "No divorce bill"
    }
  }
};

const DivorceBillTopic = () => (
  <Topic topic={topic} icon={DivorceBillIcon}>
    <p>
      Phillip Hammond (Chancellor of the Exchequer) advises we would still legally
      have to pay a divorce bill in a no deal scenario, however it would be slightly
      lower than the figure for Johnson/May's Withdrawal Agreement
      <OutboundLink href="https://www.express.co.uk/news/politics/1032512/Brexit-news-divorce-bill-philip-hammond-theresa-may">(source)</OutboundLink>.
    </p>
  </Topic>
);

export default DivorceBillTopic;
