import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Notes from '../components/Notes';
import DealHeadersRow from '../components/DealHeadersRow';
import Meta from '../components/Meta';
import PromptBoxes from '../components/PromptBoxes';
import TopicHeader from '../components/TopicHeader';

import EconomyTopic from '../topics/economy';
import TradeTariffsTopic from '../topics/trade-tarrifs';
import DivorceBillTopic from '../topics/divorce-bill';
import StrengthOfPoundTopic from '../topics/strength-of-pound';
import GdpTopic from '../topics/gdp';
import TradeTopic from '../topics/trade';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Link = (({ href, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </OutboundLink>
));

const Economy = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} title="Economy" shareImage="economy" description="See how various Brexits affect economy" />
    <IntroText />
    <TopicHeader title="Economy" />
    <DealHeadersRow />
    <EconomyTopic />
    <StrengthOfPoundTopic />
    <TradeTariffsTopic />
    <GdpTopic />
    <TradeTopic />
    <DivorceBillTopic />
    <PromptBoxes/>
    <Notes/>
  </Layout>
);

Economy.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

Economy.defaultProps = {
  data: {},
};

export default withRoot(Economy);
