import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as TradeIcon } from '../assets/box.svg';

const topic = {
  "title": "Trade outside EU",
  "results": {
    "deal": {
      "status": "red",
      "comment": "We haven't replaced our EU trade deals"
    },
    "disorderly": {
      "status": "red",
      "comment": "We haven't replaced our EU trade deals"
    },
    "remain": {
      "status": "green",
      "comment": "We have many trade deals as part of the EU"
    },
    "reform": {
      "status": "green",
      "comment": "We have many trade deals as part of the EU"
    }
  }
};

const TradeTopic = () => (
  <Topic topic={topic} icon={TradeIcon}>
    <p>
      The UK doesn't currently have any trade deals of it's own and would have
      to renegotiate many deals. The plan was to renegotiate the main deals
      before leaving the EU but the UK has failed to do so. A government
      official said that almost none of the trade deals we hoped to have by
      the time we leave have been finalised.
      <OutboundLink href="https://www.ft.com/content/c44581c2-1a75-11e9-9e64-d150b3105d21">(source)</OutboundLink>
      The UK is only expected to have a handful of trade deals by the time it leaves the EU.
    </p>
    <p>
      When we eventually renegotiate the 40 trade deals we currently have, we
      are unlikely to get a better deal as the UK is a smaller market than the
      EU and so doesn't have as much buying power. The urgency of the matter
      also means we will have less leverage. This will almost certainly lead
      to worse trade deals than we had as part of the EU.
    </p>
  </Topic>
);

export default TradeTopic;
